footer{
    padding: 45px 0 ;
    background-color: white;
    
}

footer .footer-div{
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    justify-content: center;
}

footer .signature{
    font-size: 12px;
    color: white;
    font-weight: 200;
}

footer .two,.three,.four{
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 10px;
}

footer .footer-lower{
    width: 95%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 0px;
}

footer .minor-info{
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    margin-top: 15px;
}

footer .one{
    display: flex;
    margin: 0 auto;
    display: none;
}


footer a, footer .business-info{
    text-decoration: none;
    color: #828487;
    font-size: 12px;
    font-weight: 600;
}

footer a:hover{
    color: black;
}


footer .business-info{
    width: 100%;
    margin-left: 15px;
    max-width: 150px;
    display: flex;
    flex-direction: column;
}

.business-title, .business-address, .business-phone{
    word-wrap: break-word
}

.business-address, .business-phone{
    padding-top: 8px;
}

footer img{
    pointer-events: none;
    width: auto;
    height: 100px;
}

@media screen and (min-width:800px) {
    footer .footer-lower{
        width: 95%;
        max-width: 1700px;
        flex-direction: row;
        justify-content: center;
    }

    footer .minor-info, footer .one{
        margin: 0;
    }

    footer .one{
        display: flex;
        margin-right: 9%;
    }

    .two, .three, .four{
        width: 100%;
        max-width: 300px;
        margin: 0 30px;
    }

    footer .footer-lower{
        margin-top: 15px;
    }

    .minor-info a{
        margin-bottom: 5px;
    }

    
}

.business-address{
    margin-bottom: 0px;
}

footer .signature a {
    color: white;
    font-weight: 300;
}

footer .signature a:hover {
    color: #E10613
}