.contact-info{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-info h4{
    margin-bottom: 30px;
}


.contact-info .address-row{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-bottom: 17px;
}

.contact-info .address-title{
    width: 30%;
    font-weight: 600;
}

.contact-info .address-content{
    width: 70%;
    text-decoration: none;
    color: black;
}

.color-red{
    color: #E10613 !important;
}

.last-row{
    padding-bottom: 40px;
}

.lottie-contact{
    width: 60%;
    display:flex ;
    justify-content: center;
}

.lottie-space{
    width: 100%;
    display: flex;
    justify-content: center;
}

.email:hover{
    text-decoration: underline;
}

@media screen and (min-width:800px) {
    .contact-info{
        width: 40%;
        max-width: 400px;
        margin-left: 10px;
    }
}

