/*  Fonts */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700&display=swap");

html,
body,
nav,
header,
h1,
h2,
h3,
h4,
p,
span,
a,
input,
label {
	font-family: "Inter", sans-serif;
}

body {
	background-color: black !important;
}

.Application {
	background-color: white;
	margin-top: 55px;
}

.carousel-content,
.content,
.side-menu-container {
	max-width: 1500px;
	margin: 0 auto;
}

.content {
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.fair-spacing {
	margin: 0 !important;
	margin-top: 20px !important;
	padding: 0px !important;
}

@media screen and (min-width: 800px) {
	.fair-spacing {
		margin: 0 !important;
		margin-top: 30px !important;
		padding: 0px !important;
	}
}

.side-menu-container {
	display: flex;
	flex-direction: row;
}

.product-list {
	width: 100%;
	max-width: 800px;
	display: flex;
	flex-direction: column;
	border: 1px solid red;
	max-height: 600px;
	overflow: scroll;
}

.red {
	color: #e10613;
	text-align: center;
}

.red:hover {
	color: #e10613;
}

.bold {
	font-weight: 600;
}

/* Webkit browsers */
::-webkit-scrollbar {
	width: 12px;
	height: 10px;
	background-color: white;
}

::-webkit-scrollbar-thumb {
	background-color: #52525221;
}

/* Firefox */
scrollbar {
	width: 10px;
	height: 10px;
}

scrollbar-thumb {
	background-color: #52525221;
}

/* Internet Explorer */
scrollbar {
	width: 10px;
	height: 10px;
	background-color: white;
}

scrollbar-thumb {
	background-color: #52525221;
	border-radius: 10;
}
