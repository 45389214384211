.btn {
	margin: 5px;
}

.navbar {
	margin: 0px 0px 30px 0px;
}

.alert {
	margin-bottom: 0px;
	text-align: center;
}

#logo-image {
	height: 75px;
	width: 75px;

	margin: 0px;
}

.address-tag {
	margin: 0px;
}

.fair-spacing {
	margin: 50px;
}

.form-control {
	height: 40px;
	margin: 5px;
}

#secondary-btn {
	background-color: #76abfa;
	border-color: #76abfa;
}

#search-btn {
	border-color: #0d6efd;
	color: #0d6efd;
}
#search-btn:hover {
	color: white;
	background-color: #0d6efd;
}

.add-data-btn {
	height: 25px;
}

.item-table-label {
	text-align: center;
}
#left-back-btn {
	padding-left: 10px;
	align-items: right;
	height: 40px;
}

#right-back-btn {
	padding-right: 10px;
	float: right;
	height: 40px;
}
#logo {
	margin-right: 10px;
}

#inputQuantityType {
	align-items: right;
	height: 30px;
	width: 90px;
}

.footer-spacing {
	margin: 200px;
	margin-top: 300px;
}

.delete-btn {
	height: 25px;
	padding-inline: 30px;
	align-items: right;
}

#right-align {
	align-items: right;
}

#productTable {
	height: 340px;
	overflow-x: auto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
#productTable {
	scrollbar-width: auto;
	scrollbar-color: #212529 #ffffff;
}

/* Chrome, Edge, and Safari */
#productTable::-webkit-scrollbar {
	width: 16px;
}

#productTable::-webkit-scrollbar-track {
	background: #ffffff;
}

#productTable::-webkit-scrollbar-thumb {
	background-color: #212529;
	border-radius: 10px;
	border: 3px solid #ffffff;
}

.homePageBtn {
	width: 250px;
	height: 40px;
}

.tableInput {
	outline: none;
	border: hidden;
	background-color: transparent;
	width: auto;
}

.tableDate {
	width: 95px;
}

.tableData {
	width: 80px;
	overflow: hidden;
}

.btnUpdate {
	margin-top: 0px;
	margin-bottom: 0px;
	height: 30px;
	padding: 0px 5px 0px 5px;
}

.underConstruction {
	width: 100%;
	height: 100%;
}

#underDevTitle {
	font-size: 60px;

	text-align: center;
	padding-top: 50px;
}

#underDevDescription {
	font-size: 28px;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 40px;
}

#underDevBtn {
	width: auto;
	height: auto;
	align-self: center;
	margin: auto;
	font-size: 25px;
}
#salesInfo {
	width: auto;
	height: auto;
	align-self: center;
	text-align: center;
	margin: auto;
	font-size: 30px;
	padding-top: 10px;
}
