.carousel{
    width: 100%;
    height: 200px;
    overflow: hidden;
  }
  
  
  
  .info-container{
    background-color: rgba(0, 0, 0, .5);
    width: 250px;
    height: 50px;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 140px;
    top:0;
    left: 0;
    z-index: 180;
    overflow: hidden;
    margin-left: 4%;
  }
  
  
  .info-title{
    font-size: 20px;
    font-weight: bold;
  }
  
  .info-desc{
    font-size: 12px;
    opacity: .9;
  }
  
  .info-title, .info-desc{
    color: white;
    padding-left: 5px;
  }

  .carousel-content img{
    height: 100%;
    min-height: 200px;
    object-fit: cover;
  }
  
  
  @media screen and (min-width: 800px) {
    .carousel{
      height: 280px;
    }
  
    .info-container{
      width: 480px;
      height: 100px;
      margin-left: 3%;
      margin-top: 160px;
    }
    .info-title{
      font-size: 30px;
      margin-top: 9px;
    }
    
    .info-desc{
      font-size: 18px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  
    .info-title, .info-desc{
      margin-left: 15px;
    }
  
    .arrow-row{
      margin-top: 120px
    
    }

    .carousel-content img{
      height: 100%;
      min-height: 280px;
      object-fit: cover;
    }
  }

  .info-desc-link{
    margin: 0;
    opacity: .8;
    color: white;
  }

  .info-desc-link:hover{
    opacity: 1;
    color: white;
  }

 