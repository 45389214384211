.page-break{
    width: 95%;
    max-width: 1500px;
    border-top: 1px solid #C4C4C4;
    margin: 0 auto;
    margin-top: 30px ;
    padding-bottom: 30px;
  }
  
  @media screen and (min-width: 800px) {
    .page-break{
      width: 100%;
    }
  }