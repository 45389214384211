.products-div-container{
    width: 95%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .product-card{
    width: 160px;
    margin: 0 10px;
    margin-top: 15px;
    box-shadow: 0px 5px 6px 1px rgba(0, 0,0,.2);
    text-align: center;
    overflow: hidden;
  }
  
  .product-card img{
    width: 90%;
    height: 100px;
    object-fit: cover;
    margin: 4%;
    margin-bottom: 2%;
  }
  
  .product-name-bottom{
    margin-bottom: 5px;
  }
  
  .product-card span{
    font-weight: 500;
  }
  
  @media screen and (min-width: 800px) {
    .product-card{
      width: 230px;
    }
  
    .product-card span{
      font-size: 17px;
    }
    
    .product-card img{
      height: 150px;
    }
  }
  
  .hoverable{
    cursor: pointer;
  }
  
  