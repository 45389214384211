header {
	background-color: black;
	text-align: center;
	position: fixed;
	z-index: 999;
	top: 0;
	width: 100%;
}

nav {
	font-size: 30px;
	position: absolute;
	text-align: left;
	top: 100%;
	left: 0;
	background-color: black;
	width: 100%;
	transform: scale(1, 0);
	transform-origin: top;
	transition: transform 400ms ease-in-out;
}

nav ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

nav li {
	margin-bottom: 1em;
	margin-left: 1em;
}

nav a,
.nav-link {
	color: white;
	opacity: 0.8;
	text-decoration: none;
	font-size: 1.2rem;
	transition: opacity 150ms ease-in-out;
}

nav a:hover,
.nav-link:hover {
	color: white;
	opacity: 1;
	cursor: pointer;
}

.nav-toggle {
	display: none;
}

.nav-toggle-label {
	position: absolute;
	top: 0;
	left: 0;
	margin-left: 1em;
	height: 100%;
	display: flex;
	align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
	display: block;
	background: white;
	height: 2px;
	width: 2em;
	border-radius: 2px;
	position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
	content: "";
	position: absolute;
}

.nav-toggle-label span::before {
	bottom: 7px;
}
.nav-toggle-label span::after {
	top: 7px;
}

.nav-toggle:checked ~ nav {
	transform: scale(1, 1);
}

.nav-toggle:checked ~ nav a {
	opacity: 0.8;
	transition: opacity 250ms ease-in-out 250ms;
}

.logo-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-left: 30px;
}

.logo-container img {
	width: 100%;
	height: auto;
	max-width: 95px;
	visibility: hidden;
	display: none;
}

.logo-title {
	display: flex;
	flex-direction: column;
}

.logo-title .secondary-title,
.logo-title span {
	margin: 0;
	padding: 0;
	text-align: left;
	color: white;
	text-decoration: none;
}

.logo-title span {
	font-size: 27px;
	cursor: pointer;
}

.logo-title .secondary-title {
	font-size: 18px;
	margin-left: 20px;
	margin-top: -12px;
	white-space: nowrap;
}

@media screen and (min-width: 800px) {
	.nav-toggle-label {
		display: none;
	}

	header {
		display: grid;
		grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
	}

	.logo {
		grid-column: 1 / 2;
	}

	nav {
		all: unset;
		grid-column: 3/4;
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	nav a,
	.nav-link {
		opacity: 0.8;
	}

	nav ul {
		display: flex;
		justify-content: center;
	}

	nav li {
		margin-left: 3em;
		margin-bottom: 0;
	}
	.logo-container img {
		display: block;
		visibility: visible;
	}
	.logo-container {
		margin-left: 30px;
	}
}

nav button {
	opacity: 0.8;
}

.header-btn {
	padding: 1px 10px;
	border-color: #e10613;
	color: #e10613;
}

.header-btn:hover {
	opacity: 1;
	background-color: #e10613;
	color: black;
	border-color: #e10613;
}

.logo-container img {
	pointer-events: none;
}
